import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PageFooter from '../components/PageFooter';
import Banner from '../components/Banner';

const IndexPage = () => (
  <Layout>
    <Banner />
    <section id="main" class="home">
      <Header />
    </section>
    <PageFooter />
  </Layout>
);

export default IndexPage;
