import React from 'react';
import config from '../../config';
import downloadFile from '../../src/assets/files/Algemene voorwaarden Sharewater.pdf' 

export default function PageFooter() {
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>&copy; {config.authorName}</li>
        {config.footerLinks.map(links => {
          const { name, url } = links;
          return (
            <li key={url}>
              <a href={url} className='link'>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
        <li>
          <a href={downloadFile} download className='link'><span className="label">Voorwaarden</span></a>
        </li>
        <li>
          <a href={config.authorMail} className='link'><span className="label">Email</span></a>
        </li>
      </ul>
    </footer>
  );
}
