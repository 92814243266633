import React from 'react';

import config from '../../config';
const pic = require('../assets/img/logo-sharewater.png');

export default function Banner() {
  return (
    <section className='logo'>
        <img src={pic} alt="Sharewater Logo" />
    </section>
  );
}