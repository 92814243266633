import React from 'react';

import config from '../../config';
const pic = require('../assets/images/pauline.png');

export default function Footer() {
  return (
    <header>
      <h1>Contact</h1>
      <p>Stadhouderslaan 79 <br />
      3116 HL Schiedam</p>
      <p>+31(0)6 51240700</p>
      <p><a href={config.authorMail}>info@sharewater.nl</a></p>
    </header>
  );
}
