module.exports = {
  siteTitle: 'Sharewater website', // <title>
  manifestName: 'Sharewater',
  manifestShortName: 'Sharewater', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/favicon.png',
  pathPrefix: `/home`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Sharewater',
  authorMail:'mailto:info@sharewater.nl',
  heading: 'Pauline van Rossen',
  // social
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:info@sharewater.nl',
    },
  ],
  footerLinks: [
    {
      name: 'Disclaimer',
      url: '/disclaimer',
    },
    // {
    //   name: 'Voorwaarden',
    //   url: '/',
    //   type: 'download'
    // },
    {
      name: 'Privacyverklaring',
      url: '/privacyverklaring',
    },
    // {
    //   name: 'Email',
    //   url: 'mailto:info@sharewater.nl',
    // },
  ],
};
